<template>
  <div>
    <h1>Enviar arquivo de CTes</h1>

    <v-btn @click="$router.back()" color="error">
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-file-input
      label="Selecione um arquivo"
      @change="handleFile"
    ></v-file-input>

    <v-btn
      color="success"
      @click="saveFile"
      :loading="loading"
      :disabled="loading"
    >Enviar</v-btn>

  </div>
</template>

<script>

import http from '@/modules/http';

  export default {
    data() {
      return {
        file: null,
        loading: false,
      }
    },
    methods: {
      saveFile() {
        if(!this.file) {
          this.$side({
            type    : 'error',
            msg     : 'Nenhum arquivo selecionado!',
            duration: 4000
          });

          return
        }

        this.loading = true;
        const data = new FormData();
        data.append('file', this.file);

        http.post('api/pre-cte', data)
        .then(() => {
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          });
          
          this.$router.push('/adicionar-cte');
        })
        .catch(err => {
          this.loading = false;
          this.$side({
            type    : 'error',
            msg     : 'Erro ao salvar',
            duration: 4000
          });

          console.log(err);
        });

      },
      handleFile(e) {
        this.file = e;
      }
    },
  }
</script>

<style scoped>

</style>